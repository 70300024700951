*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-right-\[1\.7rem\] {
  right: -1.7rem;
}

.-top-\[0\.1rem\] {
  top: -.1rem;
}

.bottom-\[2rem\] {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-\[2rem\] {
  left: 2rem;
}

.right-\[2\.5rem\] {
  right: 2.5rem;
}

.right-\[2rem\] {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-\[2\.5rem\] {
  top: 2.5rem;
}

.top-\[2rem\] {
  top: 2rem;
}

.z-10 {
  z-index: 10;
}

.order-first {
  order: -9999;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-end-11 {
  grid-column-end: 11;
}

.my-\[8\.8rem\] {
  margin-top: 8.8rem;
  margin-bottom: 8.8rem;
}

.mb-\[0\.5rem\] {
  margin-bottom: .5rem;
}

.mb-\[1\.4rem\] {
  margin-bottom: 1.4rem;
}

.mt-\[13rem\] {
  margin-top: 13rem;
}

.mt-\[3rem\] {
  margin-top: 3rem;
}

.mt-\[7\.4rem\] {
  margin-top: 7.4rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-\[1\.1rem\] {
  height: 1.1rem;
}

.h-\[3\.2rem\] {
  height: 3.2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-\[1\.1rem\] {
  width: 1.1rem;
}

.w-\[17rem\] {
  width: 17rem;
}

.w-\[3\.2rem\] {
  width: 3.2rem;
}

.w-full {
  width: 100%;
}

.flex-\[0_0_100\%\] {
  flex: 0 0 100%;
}

.-translate-x-\[130\%\] {
  --tw-translate-x: -130%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[130\%\] {
  --tw-translate-y: 130%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-x-20 {
  column-gap: 2rem;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
}

.bg-light {
  --tw-bg-opacity: 1;
  background-color: rgb(219 219 220 / var(--tw-bg-opacity));
}

.px-\[1rem\] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[2rem\] {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-container {
  padding-left: var(--padding-container);
  padding-right: var(--padding-container);
}

.py-\[3\.2rem\] {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.pb-\[2rem\] {
  padding-bottom: 2rem;
}

.pt-\[10\.6rem\] {
  padding-top: 10.6rem;
}

.text-center {
  text-align: center;
}

.text-11 {
  font-size: 1.1rem;
}

.text-14 {
  font-size: 1.4rem;
}

.text-16 {
  font-size: 1.6rem;
}

.text-30 {
  font-size: 3rem;
}

.leading-\[1\.4rem\] {
  line-height: 1.4rem;
}

.leading-\[2\.4rem\] {
  line-height: 2.4rem;
}

.leading-\[2\.6rem\] {
  line-height: 2.6rem;
}

.leading-\[3\.2rem\] {
  line-height: 3.2rem;
}

.-tracking-\[0\.2rem\] {
  letter-spacing: -.2rem;
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(20 20 20 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(119 119 119 / var(--tw-text-opacity));
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(219 219 220 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@font-face {
  font-family: Saans;
  src: url("Saans-light.c5db458e.woff2") format("woff2"), url("Saans-light.4ace995f.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --size: 375;
  --padding-container: 2rem;
  --vh: calc(var(--wh, 1vh) * 100);
  --ease-out: cubic-bezier(.23, 1, .32, 1);
}

@media (min-width: 768px) {
  :root {
    --size: 768;
  }
}

@media (min-width: 1024px) {
  :root {
    --size: 1024;
    --padding-container: 6.2rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --size: 1920;
  }
}

html {
  font-size: calc(10 * (100vw / var(--size)));
  line-height: 1.3;
}

body {
  font-family: Saans, sans-serif;
}

body ::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(219 219 220 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(20 20 20 / var(--tw-text-opacity));
}

span.word:not(.whitespace) {
  display: inline-block;
}

.nsb {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.nsb::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  display: none;
}

.svg-wrapper svg {
  width: 100%;
  height: auto;
}

.link-o {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@media (hover: hover) {
  .link-o:hover {
    --tw-text-opacity: 1;
    color: rgb(119 119 119 / var(--tw-text-opacity));
  }
}

.link {
  cursor: pointer;
  position: relative;
}

@media (hover: hover) {
  .link:hover:after {
    transform-origin: 100%;
    --tw-scale-x: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.link:after {
  transform-origin: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(119 119 119 / var(--tw-bg-opacity));
  will-change: transform;
  content: "";
  width: 100%;
  height: 1px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: absolute;
  bottom: -.1rem;
  left: 0;
}

@media (hover: hover) {
  .link-arrow .link-arrow-text {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .link-arrow .arrow-first, .link-arrow .arrow-last {
    transition: transform .8s var(--ease-out), opacity .8s var(--ease-out);
  }

  .link-arrow:hover .link-arrow-text {
    --tw-text-opacity: 1;
    color: rgb(119 119 119 / var(--tw-text-opacity));
  }

  .link-arrow:hover .arrow-first {
    --tw-translate-x: 130%;
    --tw-translate-y: -130%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 0;
  }

  .link-arrow:hover .arrow-last {
    --tw-translate-x: 0px;
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 1;
  }
}

.hover\:opacity-50:hover {
  opacity: .5;
}

@media (min-width: 768px) {
  .md\:top-\[0\.3rem\] {
    top: .3rem;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-end-6 {
    grid-column-end: 6;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  @media (min-width: 768px) {
    .md\:md\:col-end-12 {
      grid-column-end: 12;
    }
  }

  .md\:my-\[11rem\] {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md\:mb-\[1\.3rem\] {
    margin-bottom: 1.3rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-\[10rem\] {
    margin-top: 10rem;
  }

  .md\:w-\[34\.5rem\] {
    width: 34.5rem;
  }

  .md\:p-\[3\.5rem\] {
    padding: 3.5rem;
  }

  .md\:text-16 {
    font-size: 1.6rem;
  }

  .md\:text-22 {
    font-size: 2rem;
  }

  .md\:text-56 {
    font-size: 5.6rem;
  }

  .md\:leading-\[2\.6rem\] {
    line-height: 2.6rem;
  }

  .md\:leading-\[5rem\] {
    line-height: 5rem;
  }

  .md\:leading-\[7rem\] {
    line-height: 7rem;
  }

  .md\:tracking-\[0\] {
    letter-spacing: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:bottom-\[3\.5rem\] {
    bottom: 3.5rem;
  }

  .lg\:bottom-\[4\.2rem\] {
    bottom: 4.2rem;
  }

  .lg\:left-\[6rem\] {
    left: 6rem;
  }

  .lg\:right-\[6rem\] {
    right: 6rem;
  }

  .lg\:top-\[1\.2rem\] {
    top: 1.2rem;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:ml-\[5\.7rem\] {
    margin-left: 5.7rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:min-h-\[1100px\] {
    min-height: 1100px;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:leading-\[5rem\] {
    line-height: 5rem;
  }
}

@media (min-width: 1200px) {
  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-end-10 {
    grid-column-end: 10;
  }

  .xl\:col-end-11 {
    grid-column-end: 11;
  }

  .xl\:col-end-7 {
    grid-column-end: 7;
  }

  .xl\:min-h-\[640px\] {
    min-height: 640px;
  }
}

/*# sourceMappingURL=index.6c76bb63.css.map */
