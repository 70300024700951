@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Saans;
  src: url('fonts/Saans-light.woff2') format('woff2'),
    url('fonts/Saans-light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --size: 375;
  --padding-container: 2rem;
  --vh: calc(var(--wh, 1vh) * 100);
  --ease-out: cubic-bezier(0.23, 1, 0.32, 1);

  @screen md {
    --size: 768;
  }

  @screen lg {
    --size: 1024;
    --padding-container: 6.2rem;
  }

  @screen xl {
    --size: 1920;
  }
}

html {
  font-size: calc(10 * (100vw / var(--size)));
  line-height: 1.3;
}

body {
  font-family: Saans, sans-serif;

  ::selection {
    @apply bg-light text-dark;
  }
}

span.word:not(.whitespace) {
  @apply inline-block;
}

.nsb {
  /* stylelint-disable-next-line */
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
    appearance: none;
    width: 0;
    height: 0;
  }
}

.svg-wrapper {
  svg {
    @apply w-full h-auto;
  }
}

.link-o {
  @apply transition-colors duration-300 ease-out;

  @media (hover: hover) {
    &:hover {
      @apply text-grey;
    }
  }
}

.link {
  @apply relative cursor-pointer;

  @media (hover: hover) {
    &:hover::after {
      @apply origin-right scale-x-0;
    }
  }

  &::after {
    @apply absolute left-0 -bottom-[0.1rem] w-full h-[1px] origin-left bg-grey will-change-transform transition duration-500 ease-out;
    content: "";
  }
}

.link-arrow {
  @media (hover: hover) {
    .link-arrow-text {
      @apply transition-colors duration-500 ease-out;
    }

    .arrow-first,
    .arrow-last {
      transition:
        transform 0.8s var(--ease-out),
        opacity 0.8s var(--ease-out);
    }

    &:hover {
      .link-arrow-text {
        @apply text-grey;
      }

      .arrow-first {
        @apply translate-x-[130%] -translate-y-[130%] opacity-0;
      }

      .arrow-last {
        @apply translate-x-0 translate-y-0 opacity-100;
      }
    }
  }
}
